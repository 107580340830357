<template>
  <ion-content id="totalContent">
    <button v-on:click="btnAtras" class="btnFloat" id="btnFloatAtras">
      <!-- ion-icon name="ios-arrow-dropleft"></ion-icon -->  
      <img src="/assets/img/ios-arrow-dropleft.svg" width="24" />
    </button>
    <button v-on:click="btnAlante" class="btnFloat" id="btnFloatAlante">
      <!-- ion-icon name="ios-arrow-dropright"></ion-icon -->  
      <img src="/assets/img/ios-arrow-dropright.svg" width="24" />
    </button>
    <ion-slides
      class="slidesWeek"
      pager="false"
      @ionSlidesDidLoad="initSlide"
      @ionSlideDidChange="moveSlide"
      :key="renderKey"
    >
      <ion-slide v-for="(item, index) in weeksData" :key="index" style="overflow-y: scroll; overflow-x: hidden;">
        <div style="width: 100%; height: 100%;">
          <ion-grid style="padding: 0px;">
            <ion-row
              v-for="(itemDia, indexDia) in item.datosSemana"
              :key="indexDia"
              style="min-height: 14.25vh;"
              :style="[indexDia < (item.datosSemana.length-1) ? {'border-bottom': '1px solid #d8dddf'} : {'border-bottom': '0px'}]"
              :class="((indexDia > 4)||(itemDia.resultData.diaryView.esFestivo == 'true')) ? 'findefestivo' :  ((itemDia.resultData.diaryView.HorSalidaReal == '')&&(itemDia.resultData.diaryView.HorEntradaReal == '')&&(itemDia.resultData.diaryView.incEmpleado.length == 0)&&(new Date(itemDia.resultData.diaryView.fecRegistro).getTime() < new Date().getTime())) ? 'sinFichaje' : 'diaNormal'"
            >
              <ion-col style="padding: 0px; margin: auto;">
                <ion-grid style="padding: 0px;">
                  <!-- FILA: DÍA DE LA SEMANA -->
                  <ion-row align-items-end> 
                    <ion-col class="paddingColWeek1">
                      <div float-right>
                        <p
                          class="semTexto fontGillGray"
                        >{{date2Day(itemDia.resultData.diaryView.fecRegistro).toUpperCase()}}</p>
                      </div>
                    </ion-col>
                    <ion-col class="paddingColWeek2">
                      <div float-left>
                        <div class="semTexto PildoraBorderGray round">
                          <span
                            class="round"
                            style="font-weight: bold;"
                          >{{$global.formatDate(itemDia.resultData.diaryView.fecRegistro,null)}}</span>
                        </div>
                      </div>
                    </ion-col>
                  </ion-row>
                  <!-- FILA: LITERALES E/S -->
                  <ion-row
                    v-if="itemDia.resultData.diaryView.incEmpleado.length == 0"
                    align-items-center
                  >
                    <ion-col class="paddingColWeek1">
                      <div float-right>
                        <p class="semTexto fontGillGray">ENTRADA:</p>
                      </div>
                    </ion-col>
                    <ion-col class="paddingColWeek2">
                      <div float-left>
                        <p class="semTexto fontGillGray">SALIDA:</p>
                      </div>
                    </ion-col>
                  </ion-row>
                  <!-- FILA: BOTONES HORAS -->
                  <ion-row
                    v-if="itemDia.resultData.diaryView.incEmpleado.length == 0"
                    align-items-start
                  >
                    <ion-col class="paddingColWeek1">
                      <div float-right>
                        <div class="divPildora divPildoraTxt divPildoraTxtLeft round">
                          <input type="text" 
                          @click="clickBtn('E_sem'+index+'_dia'+indexDia);"
                          readonly="readonly"
                          class="vdatetime-input"                          
                          :class="((new Date(itemDia.resultData.diaryView.fecRegistro) > fecMax)||(new Date(itemDia.resultData.diaryView.fecRegistro) < fecMin))? 'round fillGray' :  ((itemDia.resultData.diaryView.HorSalidaReal != '')&&(itemDia.resultData.diaryView.HorEntradaReal != '')) ? 'round fillSoftOrange' : 'round fillOrange'"
                          :value="itemDia.resultData.diaryView.HorEntradaReal" />
                        </div>
                        <div class="divPildora divPildoraBtn divPildoraBtnLeft round">
                          <button
                            icon-only
                            class="round"
                            :class="((new Date(itemDia.resultData.diaryView.fecRegistro) < fecMax)&&(new Date(itemDia.resultData.diaryView.fecRegistro) > fecMin))? 'txtOrange' : 'txtGray'"
                            @click="clickBtn('E_sem'+index+'_dia'+indexDia);"
                          >
                            <!-- ion-icon name="md-arrow-dropdown"></ion-icon -->
                            <img src="/assets/img/md-arrow-dropdown.svg" width="24" />
                          </button>
                        </div>
                      </div>
                    </ion-col>
                    <ion-col class="paddingColWeek2">
                      <div float-left>
                        <div class="divPildora divPildoraTxt round">
                          <input type="text" 
                          readonly="readonly"
                          @click="clickBtn('S_sem'+index+'_dia'+indexDia);"
                          class="vdatetime-input"
                          :class="((new Date(itemDia.resultData.diaryView.fecRegistro) > fecMax)||(new Date(itemDia.resultData.diaryView.fecRegistro) < fecMin))? 'round fillGray' :  ((itemDia.resultData.diaryView.HorSalidaReal != '')&&(itemDia.resultData.diaryView.HorEntradaReal != '')) ? 'round fillSoftOrange' : 'round fillOrange'"


                          :value="itemDia.resultData.diaryView.HorSalidaReal" />
                        </div>
                        <div class="divPildora divPildoraBtn divPildoraBtnRight round">
                          <button
                            icon-only
                            class="round"
                            :class="((new Date(itemDia.resultData.diaryView.fecRegistro) < fecMax)&&(new Date(itemDia.resultData.diaryView.fecRegistro) > fecMin))? 'txtOrange' : 'txtGray'"
                            @click="clickBtn('S_sem'+index+'_dia'+indexDia);"
                          >
                            <!-- ion-icon name="md-arrow-dropdown"></ion-icon -->
                            <img src="/assets/img/md-arrow-dropdown.svg" width="24" />
                          </button>
                        </div>
                      </div>
                    </ion-col>
                  </ion-row>
                  <!-- FILA: INCIDENCAS -->
                  <ion-row
                    v-if="itemDia.resultData.diaryView.incEmpleado.length == 1"
                    style="height: 9.5238vh;"
                    align-items-center
                  >
                    <ion-col class="paddingColInc">
                      <div
                        v-for="(incidencia, index) in itemDia.resultData.diaryView.incEmpleado"
                        :key="index"
                      >
                        <p class="semTexto fontGillGray">{{incidencia.name}}</p>
                      </div>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </ion-col>
              <!-- BOTON PUNTITOS -->
              <ion-col size="auto" style="padding: 0px;">
                <button
                  icon-only
                  class="moreDayReg"
                  :disabled="((new Date(itemDia.resultData.diaryView.fecRegistro) > fecMin))? false : true"
                  @click="presentActionSheet(index,indexDia)" >
                  <!-- ion-icon slot="icon-only" name="more"></ion-icon -->
                  <img src="/assets/img/more-vertical-filled.svg" width="24" />
                </button>
              </ion-col>

              <!-- Calendario de entrada -->
              <datetime
                :input-id="'E_sem'+index+'_dia'+indexDia"
                :active="((new Date(itemDia.resultData.diaryView.fecRegistro) < fecMax)&&(new Date(itemDia.resultData.diaryView.fecRegistro) > fecMin))? true : false"
                format="HH:mm"
                type="time"
                input-class="hiddenInput"
                input-style="display:none"
                class="theme-orange"
                :max-datetime="(today.getFullYear() == new Date(itemDia.resultData.diaryView.fecRegistro).getFullYear() && today.getMonth() == new Date(itemDia.resultData.diaryView.fecRegistro).getMonth() && today.getDate() == new Date(itemDia.resultData.diaryView.fecRegistro).getDate()) ? hoursNow + ':' + minutesNow + ':' + secondsNow : null" 
                v-model="itemDia.resultData.diaryView.HorEntradaReal"
                @confirm="confirmHour"
              ></datetime>

              <!-- Calendario de salida -->
              <datetime
                :input-id="'S_sem'+index+'_dia'+indexDia"
                :active="((new Date(itemDia.resultData.diaryView.fecRegistro) < fecMax)&&(new Date(itemDia.resultData.diaryView.fecRegistro) > fecMin))? true : false"
                format="HH:mm"
                type="time"
                input-class="hiddenInput"
                input-style="display:none"
                class="theme-orange"
                :max-datetime="(today.getFullYear() == new Date(itemDia.resultData.diaryView.fecRegistro).getFullYear() && today.getMonth() == new Date(itemDia.resultData.diaryView.fecRegistro).getMonth() && today.getDate() == new Date(itemDia.resultData.diaryView.fecRegistro).getDate()) ? hoursNow + ':' + minutesNow + ':' + secondsNow : null" 
                v-model="itemDia.resultData.diaryView.HorSalidaReal"
                @confirm="confirmHour"
              ></datetime>

              <!-- JEB Calendario de vacaciones -->
              <datetime
                :input-id="'V_sem'+index+'_dia'+indexDia"
                format="yyyy-MM-dd"
                class="theme-orange"
                type="range-today"
                input-class="hiddenInput"
                input-style="display:none"
                v-model="itemDia.resultData.diaryView.objFecVacaciones"
                :phrases="{ok: 'Aceptar', cancel: 'Cancelar'}"
                :minDatetime="itemDia.resultData.diaryView.objFecVacaciones.fecIni"
                @confirm="clickVacaciones"
              ></datetime>

              <!-- JEB Calendario de ausencias -->
              <datetime
                :input-id="'A_sem'+index+'_dia'+indexDia"
                format="yyyy-MM-dd"
                class="theme-orange"
                type="range-today"
                input-class="hiddenInput"
                input-style="display:none"
                v-model="itemDia.resultData.diaryView.objFecAusencias"
                :phrases="{ok: 'Aceptar', cancel: 'Cancelar'}"
                :minDatetime="itemDia.resultData.diaryView.objFecAusencias.fecIni"
                @confirm="clickAusencias"
              ></datetime>

            </ion-row>
          </ion-grid>
        </div>
      </ion-slide>
    </ion-slides>

  </ion-content>
</template>

<script>
import { Datetime } from "@/components/vue-datetime";

export default {
  data: function() {
    return {
      auxDebug: this.$store.getters.getDebug,
      today: new Date(),
      hoursNow: (new Date().getHours() < 10) ? '0'+new Date().getHours() : new Date().getHours(),
      minutesNow: (new Date().getMinutes() < 10) ? '0'+new Date().getMinutes() : new Date().getMinutes(),
      secondsNow: (new Date().getSeconds() < 10) ? '0'+new Date().getSeconds() : new Date().getSeconds(),
      openDayId: null,
      checkCarga: true,
      renderKey: 0,
      config: new Object(),
      weeksData: new Array(),
    };
  },
  computed: {
    fecMin: function() {
      var date = new Date();
      date.setDate(date.getDate() - this.config.cfg_numDiasAtras);
      return date;
    },
    fecMax: function() {
      var date = new Date();
      date.setDate(date.getDate() + this.config.cfg_numDiasAlante);
      return date;
    }
  },
  watch: {
    weeksData: function() {
      //Agregamos con cada cambio los objetos de vacaciones y ausencias
      window.$.each(this.weeksData, function(i,weeksData) {
        window.$.each(weeksData.datosSemana, function(i,datosSemana) {
          var auxData = datosSemana.resultData.diaryView;
          auxData.objFecAusencias = { fecIni: auxData.fecRegistro.substr(0,10), fecFin: auxData.fecRegistro.substr(0,10) };
          auxData.objFecVacaciones = { fecIni: auxData.fecRegistro.substr(0,10), fecFin: auxData.fecRegistro.substr(0,10) };
        });    
      });
    }
  },
  methods: {
    clickBtn: function(id) {
      this.openDayId = id;
      window.$("#" + id).click();
    },
    presentActionSheet(indexSemana, indexDia) {

      var idBtnAction = "D_sem" + indexSemana + "_dia" + indexDia;
      var idBtnAusencias = "A_sem" + indexSemana + "_dia" + indexDia;
      var idBtnVacaciones = "V_sem" + indexSemana + "_dia" + indexDia;
      var auxInfoDia = this.weeksData[indexSemana].datosSemana[indexDia].resultData.diaryView;
      var auxTieneFechaEntrada = auxInfoDia.HorEntradaReal !== "" ? 1 : 0;
      var auxTieneFechaSalida = auxInfoDia.HorSalidaReal !== "" ? 1 : 0;
      var auxTieneIncidencia = (auxInfoDia.incEmpleado.length > 0) ? true : false; 
      var auxEsBorrable = (auxTieneIncidencia) ? (auxInfoDia.incEmpleado[0].esEliminable == "false") ? false :  true : true;
      var auxEsFestivo = ((indexDia > 4)||(auxInfoDia.esFestivo == "true")) ? true :  false;

      var that = this;
      var auxDiaCompleto =
        auxInfoDia.incEmpleado.length +
        auxTieneFechaEntrada +
        auxTieneFechaSalida;
      
      var auxBtns = new Array();
      var btnBorrar = {
        text: "Borrar",
        role: "destructive",
        icon: "trash",
        handler: () => {
          this.delRegistro(idBtnAction);
        }
      };
      if (auxEsBorrable && auxDiaCompleto > 0) {
        auxBtns.push(btnBorrar);
      }

      var btnAgVac = {
        text: "Agregar Vacaciones",
        icon: "airplane",
        handler: () => {
          that.abrirDatepicker(idBtnVacaciones);
        }
      };
      if((auxDiaCompleto == 0)&&(!auxEsFestivo)){
        auxBtns.push(btnAgVac);
      }

      var btnAgAus = {
        text: "Agregar Ausencia",
        icon: "person-add",
        handler: () => {
          that.abrirDatepicker(idBtnAusencias);
        }
      };
      if((auxDiaCompleto == 0)&&(!auxEsFestivo)){
        auxBtns.push(btnAgAus);
      }

      var btnCancelar = {
        text: "Cancelar",
        icon: "close",
        role: "cancel"
      };
      auxBtns.push(btnCancelar);

      return this.$ionic.actionSheetController
        .create({
          header: "Acciones",
          buttons: auxBtns
        })
        .then(a => a.present());
    },
    abrirDatepicker: function(id) {
      window.$("#" + id).click();
    },
    clickVacaciones: function(diaAus) {
      var arrSplit = diaAus.id.split("_");
      var indexSemana = arrSplit[1].substring(3);
      var indexDia = arrSplit[2].substring(3);
      var auxInfoDia = this.weeksData[indexSemana].datosSemana[indexDia].resultData.diaryView;
      this.selectAus(
        "9",
        auxInfoDia.objFecVacaciones.fecIni,
        auxInfoDia.objFecVacaciones.fecFin
      );
    },
    clickAusencias: function(diaAus) {
      var arrSplit = diaAus.id.split("_");
      var indexSemana = arrSplit[1].substring(3);
      var indexDia = arrSplit[2].substring(3);
      var auxInfoDia = this.weeksData[indexSemana].datosSemana[indexDia].resultData.diaryView;
      this.selectAus(
        "A",
        auxInfoDia.objFecAusencias.fecIni,
        auxInfoDia.objFecAusencias.fecFin
      );
    },
    selectAus: function(idAus, fIni, fFin) {
      var that = this;
      this.$store.dispatch('setIncidencia',{'tipo': "W",'idAus': idAus,'fIni': fIni,'fFin': fFin}).then( async () => {
        that.$store.getters.alertIonic("OK", "", this.config.msg_confirmIncidencia);
        this.$global.ponerCarga();
        await this.cargaInicial();
        this.$global.quitarCarga();
      }).catch((reason) => {
        console.log('selectAus > setIncidencia > rejected: '+reason);
        that.$store.getters.alertIonic("OK", "Error!", reason);
      }); 
    },
    delRegistro: function(id) {
      
      var arrSplit = id.split("_"); //Tipo: "D_sem1_dia0"
      var indexSemana = arrSplit[1].substring(3);
      var indexDia = arrSplit[2].substring(3);
      var auxInfoDia = this.weeksData[indexSemana].datosSemana[indexDia].resultData.diaryView;
      
      var that = this;
      this.$store.dispatch('delRegistro', {'fecReg': auxInfoDia.fecRegistro.substr(0,10)}).then( async() => {
        that.$store.getters.alertIonic("OK", "", "Jornada borrada correctamente");
        this.$global.ponerCarga();
        await this.cargaInicial();
        this.$global.quitarCarga();
      }); 
    },
    confirmHour: async function(objRet) {

      this.$global.ponerCarga();
      var arrSplit = objRet.id.split("_"); //Tipo: "S_sem1_dia0"
      var tipo = arrSplit[0];
      //var litTipo = tipo == "S" ? "Salida" : "Entrada";
      //var urlTipo = tipo == "S" ? "setExit" : "setEntry";
      var indexSemana = arrSplit[1].substring(3);
      var indexDia = arrSplit[2].substring(3);

      //fecha horaEntrada horaSalida
      var auxInfoDia = this.weeksData[indexSemana].datosSemana[indexDia];
      var auxData = auxInfoDia.resultData.diaryView;
      var horaWS = tipo == "S" ? auxData.HorSalidaReal : auxData.HorEntradaReal;
      var paramURL = "&time=" + horaWS
      var segsTrabajados = null;
      var chkExcesoHora = false;
      var chkMsgOk = true;
      
      //Si en el día hemos introducido la entrada y la salida comprobamos mensajes
      if (auxData.HorEntradaReal && auxData.HorSalidaReal){
        let dIni = Date.parse("2000-01-01T" + auxData.HorEntradaReal + ":00");
        let dFin = Date.parse("2000-01-01T" + auxData.HorSalidaReal + ":00");

        segsTrabajados = (dFin - dIni) / 1000;
        await this.$store.getters.preguntasSalida({indexDia: indexDia,
                                             chkComida: auxData.preguntaComida, 
                                             segsJornada: auxData.segundosTeoricos,
                                             segsJornadaComida: auxData.segundosTeoricosComida,
                                             segsJornadaTrabajada: segsTrabajados }).then((res) => {
          chkExcesoHora = (res.respExcesoHoras) ? true : false;
          paramURL += (res.respExcesoHoras)  ? "&excesoTiempo=" + res.respExcesoHoras  : ""; 
          paramURL += (res.respExcesoComida) ? "&excesoComida=" + res.respExcesoComida : "";
          paramURL += (res.respTeletrabajo) ? "&teletrabajo=" + res.respTeletrabajo : "";

        }).catch((reason) => {
            console.log('confirmHour > preguntasSalida > rejected: '+reason);
            chkMsgOk = false;
            auxData.HorEntradaReal = "";
            auxData.HorSalidaReal = "";
        });
      }else{
        await this.$store.getters.preguntasFinde({indexDia: indexDia}).then(() => {
          paramURL += "&excesoTiempo="; 
          paramURL += "&excesoComida=";
        }).catch((reason) => {
            console.log('confirmHour > preguntasFinde > rejected: '+reason);
            chkMsgOk = false;
            auxData.HorEntradaReal = "";
            auxData.HorSalidaReal = "";
        });
      }

      var that = this;
      if(tipo == "S" && chkMsgOk){
        this.$store.dispatch('setSalida',{'params':paramURL,'dia':this.$global.formatDate(auxData.fecRegistro, "M4")}).then(() => {
          let msgAlert = this.config.msg_confirmFichajeSalida;
            if(chkExcesoHora){
              var d = Number(segsTrabajados);
              var h = Math.floor(d / 3600);
              var m = Math.floor((d % 3600) / 60);
              //var s = Math.floor(d % 3600 % 60);

              var hDisplay = h > 0 ? h + (h == 1 ? " hora, " : " horas, ") : "";
              var mDisplay = m > 0 ? m + (m == 1 ? " minuto, " : " minutos ") : "";
              var totDisplay = hDisplay + mDisplay; // + sDisplay;

              //msgAlert += " Presencia de " + totDisplay 
              msgAlert = this.config.msg_confirmFichajeSalidaHorario.replace("##TOTAL_HORAS##", totDisplay);
            }
            that.$store.getters.alertIonic("OK", "", msgAlert);
          })
      }else if(tipo == "E" && chkMsgOk){
        this.$store.dispatch('setEntrada',{'params':paramURL,'dia':this.$global.formatDate(auxData.fecRegistro, "M4")}).then(() => {
          that.$store.getters.alertIonic("OK", "", this.config.msg_confirmFichajeEntrada);
        });
      }
      this.$global.quitarCarga();
    },
    date2Day: function(date) {
      var auxDate = date;
      if (typeof auxDate === "string") {
        auxDate = new Date(auxDate);
      }

      var days = [
        "Domingo",
        "Lunes",
        "Martes",
        "Miércoles",
        "Jueves",
        "Viernes",
        "Sábado"
      ];
      return days[auxDate.getDay()];
    },
    initSlide: async function(e) {
      this.slides = e.target;
      await this.slides.slideTo(1, 0, false);
      this.comprobarBloqueo();
     
    },
    comprobarBloqueo(){
      //console.log("this.comprobarBloqueo();");
      var isMobile = navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i);
      if (isMobile == null){
        this.slides.lockSwipes(true);
      }else{
        this.slides.lockSwipes(false);
      }
    },
    btnAlante: async function(){
      await this.slides.lockSwipes(false);
      await this.slides.slideNext();
      this.comprobarBloqueo();
    },
    btnAtras: async function(){
      await this.slides.lockSwipes(false);
      await this.slides.slidePrev();
      this.comprobarBloqueo();
    },
    moveSlide: async function(e) {
      if (this.checkCarga) {
        
        var that = this;
        let bCarga = false;
        let typeCarga = ""; //["INI" | "FIN"]
        await e.target.isBeginning().then(function(bRet){ if(bRet){
            bCarga = true;
            typeCarga = "INI";
            that.checkCarga = false;
        } });

        await e.target.isEnd().then(function(bRet){ if(bRet){
            bCarga = true;
            typeCarga = "FIN";
            that.checkCarga = false;
        } });

        //Si estamos en la primera o ultima, tendremos que cargar informacion
        if(bCarga){
          that.slides.getActiveIndex().then(async function(index) {

            //Comprobamos si existe la información de la semana anterior
            let semActual = new Date(that.weeksData[index].fecIniSemana);
            var semComprobar = new Date(semActual.getTime());
            (typeCarga == "INI") ? semComprobar.setDate(semComprobar.getDate() - 7) : semComprobar.setDate(semComprobar.getDate() + 7);

            var datosSemana = that.$store.getters.getweekView(semComprobar);
            if (datosSemana.length == 0) {
                that.$global.ponerCarga();
                await that.$store.dispatch('wsWeekView',{'fecWeek':semComprobar,'act':true}).then(() => {
                  datosSemana = that.$store.getters.getweekView(semComprobar);
                  datosSemana = datosSemana[0];
                  that.$global.quitarCarga();
                })
            }else{
              datosSemana = datosSemana[0];
            }

            //Recolocamos el array de datos según el tipo. Los slices siempre van a ser 3, son los datos los que rotan
            if(typeCarga == "INI"){
              that.weeksData.unshift(datosSemana);
              that.weeksData.pop();
            }else{
              that.weeksData.push(datosSemana);
              that.weeksData.shift();
            }

            //Esperamos a la actualización del DOM
            await that.$nextTick(async function () {
              //Nos movemos a la slice de enmedio
              await that.slides.lockSwipes(false);
              that.slides.slideTo(1, 0, false).then(function() {
                that.checkCarga = true;
                that.comprobarBloqueo();
              });
            })
            
            
          }) //getActiveIndex
        } //bCarga
      }//checkCarga
    },
    cargaInicial: async function(){
      //Calculamos el primer día de la semana
      var d = new Date();
      var hoy = d.getDay();
      var dif = d.getDate() - hoy + (hoy == 0 ? -6 : 1);
      var lunes = new Date(new Date(d.setDate(dif)).setHours(0,0,0,0));
      //var lunes = d.getDate() - d.getDay();
      //    lunes = new Date(d.setDate(lunes));

      //Cargamos la semana anterior
      var semAnterior = new Date(lunes.getTime());
      semAnterior.setDate(semAnterior.getDate() - 7);
      
      //Y la siguiente
      var semPosterior = new Date(lunes.getTime());
      semPosterior.setDate(semPosterior.getDate() + 7);

      //Calculamos una semana mas para temas de flitradro
      var finFiltrado = new Date(semPosterior.getTime());
      finFiltrado.setDate(finFiltrado.getDate() + 7);


      await this.$store.dispatch('wsWeekView',{'fecWeek':semAnterior,'act':true}).then(async () => {
        await this.$store.dispatch('wsWeekView',{'fecWeek':lunes,'act':true}).then(async () => {
          await this.$store.dispatch('wsWeekView',{'fecWeek':semPosterior,'act':true}).then(async () => {
            this.weeksData = this.$store.getters.getAllWeekView.filter(function (e) {
                return new Date(e.fecIniSemana) >= semAnterior && new Date(e.fecFinSemana) < finFiltrado;
            });
            console.log(this.weeksData);
            this.config = this.$store.getters.getJsonConfig;
            this.renderKey += 1;
          });
        });
      });
    }
  },
  components: {
    datetime: Datetime 
  },
  async mounted() {
    this.$global.ponerCarga();
    this.$store.commit("SET_LAYOUT", "principal-layout");
    await this.cargaInicial();
    this.$global.quitarCarga();
  }
};
</script>

<style scoped>
@import "/assets/css/global.css";
@import "/assets/css/semanal.css";
</style>
