<template>
  <div id="app">
    <menuChorario v-if="checkMenu"></menuChorario>

    <div class="ion-page" main>
      <ion-header style="width: 30% !important;">
        <ion-toolbar v-if="checkMenu">
          <ion-buttons slot="start">
            <ion-menu-button></ion-menu-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>
      <ion-content class="ion-no-padding" :class="classFondo" fullscreen style="position:absolute;" id="mainContent">
        <router-view :segsTrabajados="segs" />
      </ion-content>
      <ion-footer v-if="checkFooter">
        <ion-row v-if="checkPrincipal">
          <ion-col>
            <img src="/assets/img/footer/entrada.png" class="imgFooter" />
            <span class="spanFooter">{{this.$store.getters.getHour('E')}}</span>
          </ion-col>
          <ion-col>
            <img src="/assets/img/footer/tiempo.png" class="imgFooter" />
            <span class="spanFooter">{{$global.secondsToHms(this.$store.getters.getSecWorked+this.segs)}}</span>
          </ion-col>
          <ion-col>
            <img src="/assets/img/footer/salida.png" class="imgFooter" />
            <span class="spanFooter">{{this.$store.getters.getHour('S')}}</span>
          </ion-col>          
        </ion-row>
        <ion-row v-else>
          <ion-col>
            <img src="/assets/img/footer/entrada.png" class="imgFooter" style="visibility:hidden" />            
          </ion-col>
        </ion-row>

      </ion-footer>
    </div>

    <ion-menu-controller></ion-menu-controller>
    <ion-alert-controller></ion-alert-controller>
  </div>
</template>

<script>
import menuChorario from "../menu.vue";

export default {
  data: function() {
    return {
      segs: null,
      segsWorkAnt: null,
      jornCompl: false,
      segsInterval: 1
    }
  },
  computed: {
    classFondo: function() {
      var auxRuta = this.$route.path.split("/")[1];
      var retClassName = "f1";
      if (auxRuta == "semanal") {
        retClassName = "f2";
      }else if (auxRuta == "contacta" || auxRuta == "informe") {
        retClassName = "f3";
      }
      return retClassName;
    },
    checkFooter: function() {
      var auxRuta = this.$route.path.split("/")[1];
      var retCheck = false;
      if (auxRuta == "principal") {
        retCheck = true;
      }
      return retCheck;      
    },
    checkMenu: function() {
      var auxRuta = this.$route.path.split("/")[1];
      var retCheck = true;
      if (auxRuta == "cerrar") {
        retCheck = false;
      }
      return retCheck;      
    },
    checkPrincipal: function() {
      var auxRuta = this.$route.path.split("/")[1];
      var retCheck = false;
      if (auxRuta == "principal") {
        retCheck = true;
      }
      return retCheck;      
    }
  },
  components: {
    menuChorario
  },
  created: function() {
      setInterval(() => {
        if (this.$store.getters.getSecWorked != null){
          //if (!this.segs && this.segs !== 0){
          if(this.segsWorkAnt != this.$store.getters.getSecWorked){
            this.segs = 0;
            this.segsWorkAnt =  this.$store.getters.getSecWorked
          }else{
            if((!this.jornCompl)){
              this.segs += this.segsInterval;  
              this.jornCompl = this.$store.getters.getComplete;
            }
          } 
        }
      }, (this.segsInterval*1000));
  },
  mounted: function() {

    ////CONTROL DE SESIONES
    var controlSesiones = !this.$store.getters.getDebug;

    if (!this.$store.getters.getCredential && controlSesiones){
      this.$store.commit("SET_CREDENTIAL", null);
      this.$store.getters.alertIonic(
        "session",
        "Error",
        "No existe una sesión válida, por favor vuelve a iniciar sesión en la aplicación"
      );
    }else{
      this.jornCompl = this.$store.getters.getComplete;
      this.segs = this.$store.getters.getSecWorked;
    }
  }
};
</script>

<style scoped>
@import "/assets/css/global.css";
</style>
