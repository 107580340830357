import axios from "axios";
import https from "https";

export default {
    async requestAuthorization({ state }) {
        if (state.debug) {
            console.log("------------------------");
            console.log("actions > requestAuthorization");
            console.log("");
        }
        const URL = state.authURL + "?client_id="+state.clientId+"&response_type=code&scope=openid&redirect_uri="+encodeURIComponent(state.redirectURL);
        window.location.replace(URL)
    },
    getUrlParameter({state},{sParam}) {
        if (state.debug) {
            console.log("------------------------");
            console.log("actions > getUrlParameter");
            console.log("sParam: "+sParam)
            console.log("");
        }
        
        var sPageURL = window.location.search.substring(1),
            sURLVariables = sPageURL.split('&'),
            sParameterName,
            i;
    
        for (i = 0; i < sURLVariables.length; i++) {
            sParameterName = sURLVariables[i].split('=');
    
            if (sParameterName[0] === sParam) {
                return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
            }
        }
    },
    async swapAuthCodeForAccessToken({ dispatch, commit, state }, {authCode}) {
        if (state.debug) {
            console.log("------------------------");
            console.log("actions > swapAuthCodeForAccessToken");
            console.log("");
        }

        const instanceAxios = axios.create({
            httpsAgent: new https.Agent({
                rejectUnauthorized: false
            })
        });

        const formData = new FormData();
        formData.append('code', authCode);

        return instanceAxios
            .post(state.tokenURL, formData)
            .then(async res => {
                var resJSON = res.data;
                if (typeof res.data == "string") {
                    resJSON = JSON.parse(res.data.replace(/\r?\n|\r/g, ' '));
                }
                commit('SET_ACCESS_TOKEN', resJSON.access_token);
                await dispatch('wsLogin');
            })

    },
    async wsLogin({ getters, commit, state }) {

        if (state.debug) {
            console.log("------------------------");
            console.log("actions > wsLogin");
            console.log("");
            return new Promise(function (resolve) {
                if (state.debug == 1) {
                    commit('SET_CREDENTIAL', 'okLocal')
                    var objConfig = getters.getLocalConfiguration;
                    console.log(objConfig);
                    commit('SET_CONFIG', objConfig);
                    commit('SET_USERNAME', 'SANCHEZ DE LA CUESTA SANCHEZ DE IBARGUEN, PAULA')
                    resolve();
                }
            });
        } else {
            const URL = state.urlWS + "LoginSSO";
            //var URLTot = state.urlGateway + "?urlWs=" + URL + "&usu=" + state.user + "&pass=" + state.pass
            //console.log(" > URLTot: " + URLTot)

            const instanceAxios = axios.create({
                httpsAgent: new https.Agent({
                    rejectUnauthorized: false
                })
            });
            const formData = new FormData();
            formData.append('urlWs', URL);
            formData.append('authCode', state.accessToken);

            return instanceAxios
                //.get(URLTot)                
                .post(state.urlGateway, formData)
                .then(res => {
                    //console.log(res);
                    var resJSON = res.data;
                    if (typeof res.data == "string") {
                        resJSON = JSON.parse(res.data.replace(/\r?\n|\r/g, ' '));
                    }
                    commit('SET_CREDENTIAL', resJSON.content.credential);
                    commit('SET_USERNAME', resJSON.content.name);
                    commit('SET_CONFIG', getters.transformJsonConfig(resJSON.content.configuration));
                })

        }

    },
    async wsDiaryView({ getters, commit, state }, { fecdia, act }) {

        if (state.debug) {
            console.log("------------------------");
            console.log("actions > wsDiaryView");
            console.log(" #fecdia: " + fecdia);
            console.log(" #act: " + act);
            console.log("");
        }

        //Si diaryView no está vacío, no hacemos nada
        if ((Object.entries(state.diaryView).length === 0 && state.diaryView.constructor === Object) || (!act)) {
            if (state.debug) {
                console.log(" > No hay ningún diaryView")
                console.log(" > state.debug = " + state.debug)
                await new Promise(r => setTimeout(r, 100));
                return new Promise(function (resolve) {
                    if (act) {
                        commit('SET_CREDENTIAL', 'okLocal')
                        var objDia = getters.getLocalDiaryView(fecdia);
                        commit('SET_DIARYVIEW', objDia);
                        console.log(objDia);
                    }
                    resolve();
                })
            } else {

                const URL = state.urlWS + "action?getDay";
                const instanceAxios = axios.create({
                    httpsAgent: new https.Agent({
                        rejectUnauthorized: false
                    })
                });

                //Comentamos esto mientras se arregla el WS
                //const AuthStr = "Basic " + this.$store.getters.getCredential;
                //const AuthStr = "Basic " + btoa(this.user + ":" + this.password);
                //instanceAxios.defaults.headers.common["Authorization"] = AuthStr;
                //instanceAxios.defa  ults.headers.common["Access-Control-Allow-Origin"] = "*";
                const formData = new FormData();
                formData.append('urlWs', URL);
                formData.append('authCode', state.accessToken);

                return instanceAxios
                    .post(state.urlGateway, formData)
                    .then(res2 => {
                        var resJSON = res2.data;

                        if (typeof res2.data == "string") {
                            resJSON = JSON.parse(res2.data.replace(/\r?\n|\r/g, ' '));
                        }
                        if (act) {
                            commit('SET_CREDENTIAL', resJSON.credential);
                            commit('SET_DIARYVIEW', resJSON.diaryView);
                        }
                    })
            }
        } else {
            if (state.debug) {
                console.log(" > diaryView encontrado... no hacemos nada")
            }
            return new Promise(function (resolve) {
                resolve();

            });
        }
    },
    async wsWeekView({ getters, commit, state }, { fecWeek, act }) {

        if (state.debug) {
            console.log("------------------------");
            console.log("actions > wsWeekView");
            console.log(" #fecWeek: " + fecWeek);
            console.log("");
        }

        //Comprobamos si tenenmos que cargar
        var auxfec = new Date(fecWeek.getTime());
        auxfec.setHours(0, 0, 0, 0);

        var arrItemsFilter = window.$.grep(state.weekView, function (item) {
            let auxFecIniSemana = new Date(item.fecIniSemana);
            auxFecIniSemana.setHours(0, 0, 0, 0);
            return auxFecIniSemana.getTime() == auxfec.getTime()
        });

        if (arrItemsFilter.length > 0) { //Si existe... no lo cargamos
            if (state.debug) { console.log(" > Existe... no lo cargamos"); }
            return new Promise(function (resolve) {
                resolve(arrItemsFilter[0]);
            });
        } else {
            if (state.debug) {
                return new Promise(async function (resolve) {
                    var objSem = getters.getLocalweekView(fecWeek);
                    if (act) {
                        commit('SET_CREDENTIAL', 'okLocal')
                        commit('ADD_WEEKVIEW', objSem)
                    }
                    resolve(objSem);
                });
            } else {

                let day = fecWeek.getDate();
                let month = fecWeek.getMonth() + 1;
                let year = fecWeek.getFullYear();
                day = day < 10 ? "0" + day : day;
                month = month < 10 ? "0" + month : month;
                let formatFecWeek = year + "-" + month + "-" + day;

                const URL = state.urlWS + "action?getWeek=" + formatFecWeek;
                const instanceAxios = axios.create({
                    httpsAgent: new https.Agent({
                        rejectUnauthorized: false
                    })
                });

                const formData = new FormData();
                formData.append('urlWs', URL);
                formData.append('authCode', state.accessToken);

                //Comentamos esto mientras se arregla el WS
                //const AuthStr = "Basic " + this.$store.getters.getCredential;
                //const AuthStr = "Basic " + btoa(this.user + ":" + this.password);
                //instanceAxios.defaults.headers.common["Authorization"] = AuthStr;
                //instanceAxios.defa  ults.headers.common["Access-Control-Allow-Origin"] = "*";
                return instanceAxios
                    //.get(state.urlGateway + "?urlWs=" + URL + "&usu=" + state.user + "&pass=" + state.pass)
                    .post(state.urlGateway, formData)
                    .then(res2 => {
                        var resJSON = res2.data;

                        if (typeof res2.data == "string") {
                            resJSON = JSON.parse(res2.data.replace(/\r?\n|\r/g, ' '));
                        }
                        //state.weekView.push(resJSON.WeekView);
                        if (act) { commit('ADD_WEEKVIEW', resJSON.WeekView); }
                        return resJSON.WeekView;
                    })
            }
        }
    },
    async wsReportInfo({ dispatch, state }, { fIni, fFin }) {

        //Convertimos las fechas...
        let dateWithoutHour = fIni.split("T")[0]      //2020-03-06T10:52:22.311+01:00
        let arrValDate = dateWithoutHour.split("-");
        var dIni = new Date(arrValDate[0], arrValDate[1].toString() - 1, 1);
        dateWithoutHour = fFin.split("T")[0]      //2020-04-06T10:52:22.311+01:00
        arrValDate = dateWithoutHour.split("-");
        var dFin = new Date(arrValDate[0], arrValDate[1].toString(), 0);

        if (state.debug) {
            console.log("@@@@@@@@@@@@@@@@@@");
            console.log("actions > getReportInfo");
            console.log(" #fIni: " + dIni);
            console.log(" #fFin: " + dFin);
            console.log("");
        }

        //Recorremos los meses
        const monthNames = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio","Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"]
        var arrDatos = new Array();
        for (
            var i = new Date(dIni.getTime());
            i <= dFin;
            i.setMonth(i.getMonth()+1)
        ) {
            let auxYear = i.getFullYear();
            let auxMonth = i.getMonth();

            //Buscamos la fecha de la primera semana y de la última del mes
            let dIniMonth = new Date(auxYear, auxMonth, 1);
            let dFinMonth = new Date(auxYear, auxMonth+1, 0);

            var firstWeek = new Date();
            var lasttWeek = new Date();
            let day = dIniMonth.getDay();
            let diff = dIniMonth.getDate() - day + (day == 0 ? -6 : 1);
            firstWeek.setTime(dIniMonth.getTime());
            firstWeek.setDate(diff);
            day = dFinMonth.getDay();
            diff = dFinMonth.getDate() - day + (day == 0 ? -6 : 1);
            lasttWeek.setTime(dFinMonth.getTime());
            lasttWeek.setDate(diff);

            var arrSemanas = new Array();
            if(state.debug){
                console.log(" #auxDate: " + auxMonth + "-" + auxYear);
                console.log(" #firstWeek: " + firstWeek);
                console.log(" #lasttWeek: " + lasttWeek);
                console.log("");
            }

            for (
                var j = new Date(firstWeek.getTime());
                j <= lasttWeek;
                j.setDate(j.getDate() + 7)
            ) {
                if (state.debug) { console.log(" > Semana del" + j) }
                var datos = await dispatch('wsWeekView', { 'fecWeek': j, 'act': false })
                if (state.debug) { console.log(datos); }
                var objSemana = new Object();
                objSemana.fecIni = datos.fecIniSemana;
                objSemana.datosSemana = datos.datosSemana;
                arrSemanas.push(objSemana);
            }

            var objMes = new Object();
            objMes.name = monthNames[auxMonth] + " " + auxYear;
            objMes.fIniMes = dIniMonth;
            objMes.fFinMes = dFinMonth;
            objMes.datosMes = arrSemanas;
            arrDatos.push(objMes);
        }

        if (state.debug) {
            console.log(arrDatos)
            console.log("@@@@@@@@@@@@@@@@@@");
        }
        
        return arrDatos;
    },
    async addSecWorked({ commit, state }, { secs }) {
        if (state.debug) {
            console.log("------------------------");
            console.log("actions > addSecWorked");
            console.log(" #secs: " + secs);
            console.log("");
        }
        commit('ADD_SECWORKED', secs)
    },
    async setEntrada({ commit, state }, { params, dia }) {
        if (state.debug) {
            console.log("------------------------");
            console.log("actions > setEntrada");
            console.log(" #params: " + params);
            console.log(" #dia: " + dia);
            console.log("");
        }

        //Cuando se realiza una accion... vaciamos datos que puedan estar afectados
        if (dia) {  //En el semanal... borramos todo, si no se producen problemas en el informe
            if (state.debug) { console.log(" > Borramos todo!!"); }
            let objEmpty = new Object();
            commit('SET_DIARYVIEW', objEmpty)
            let arrEmpty = new Array();
            commit('SET_WEEKVIEW', arrEmpty)
        } else {    //En el diario... borramos los datos de las semanas
            if (state.debug) { console.log(" > Borramos la semana!!"); }
            let arrEmpty = new Array();
            commit('SET_WEEKVIEW', arrEmpty)
        }

        var URL = (dia) ? state.urlWS + "action?setEntry=" + dia : state.urlWS + "action?setEntry";
        //var URLTot = state.urlGateway + "?urlWs=" + URL + "&usu=" + state.user + "&pass=" + state.pass
        //URLTot += (params) ? params : "";
        URL += (params) ? params : "";
        //console.log(" > URLTot: " + URLTot);
        //console.log(" > state.debug: " + state.debug);

        if (state.debug) {
            return new Promise(function (resolve) {
                resolve();
            })
        } else {
            const instanceAxios = axios.create({
                httpsAgent: new https.Agent({
                    rejectUnauthorized: false
                })
            });
            const formData = new FormData();
            formData.append('urlWs', URL);
            formData.append('authCode', state.accessToken);
            return instanceAxios
                //.get(URLTot)
                .post(state.urlGateway, formData)
                .then(res2 => {
                    var resJSON = res2.data.resultData;

                    if (typeof res2.data == "string") {
                        resJSON = JSON.parse(res2.data.replace(/\r?\n|\r/g, ' '));
                    }
                    if (!dia) {
                        commit('SET_DIARYVIEW', resJSON.diaryView)
                    }
                    commit('SET_CREDENTIAL', resJSON.credential)
                })
        }
    },
    async setSalida({ commit, state }, { params, dia }) {

        if (state.debug) {
            console.log("------------------------");
            console.log("actions > setSalida");
            console.log(" #params: " + params);
            console.log(" #dia: " + dia);
            console.log("");
        }

        //Cuando se realiza una accion... vaciamos datos que puedan estar afectados
        if (dia) {  //En el semanal... borramos el día actual
            if (state.debug){ console.log(" > Borramos el día!!"); }
            let objEmpty = new Object();
            commit('SET_DIARYVIEW', objEmpty)
        } else {    //En el diario... borramos los datos de las semanas
            if (state.debug) { console.log(" > Borramos la semana!!"); }
            let arrEmpty = new Array();
            commit('SET_WEEKVIEW', arrEmpty)
        }

        var URL = (dia) ? state.urlWS + "action?setExit=" + dia : state.urlWS + "action?setExit";
        URL += (params) ? params : "";
        //var URLTot = state.urlGateway + "?urlWs=" + URL + "&usu=" + state.user + "&pass=" + state.pass
        //URLTot += (params) ? params : "";
        
        if (state.debug) {
            return new Promise(function (resolve) {
                resolve();
            })
        } else {
            const instanceAxios = axios.create({
                httpsAgent: new https.Agent({
                    rejectUnauthorized: false
                })
            });
            const formData = new FormData();
            formData.append('urlWs', URL);
            formData.append('authCode', state.accessToken);
            return instanceAxios
                .post(state.urlGateway, formData)
                .then(res2 => {
                    var resJSON = res2.data.resultData;

                    if (typeof res2.data == "string") {
                        resJSON = JSON.parse(res2.data.replace(/\r?\n|\r/g, ' '));
                    }
                    if (state.debug){ console.log(resJSON.diaryView); }
                    if (!dia) {
                        commit('SET_DIARYVIEW', resJSON.diaryView)
                    }
                    commit('SET_CREDENTIAL', resJSON.credential)
                })
        }
    },
    async setIncidencia({ commit, state }, { tipo, idAus, fIni, fFin }) {

        if (state.debug){ 
            console.log("------------------------");
            console.log("actions > setIncidencia");
            console.log(" #tipo: " + tipo);
            console.log(" #idAus: " + idAus);
            console.log(" #fIni: " + fIni);
            console.log(" #fFin: " + fFin);
            console.log("");

            console.log(" > Borramos el día y la semana!!");
        }
        let objEmpty = new Object();
        let arrEmpty = new Array();
        commit('SET_DIARYVIEW', objEmpty)
        commit('SET_WEEKVIEW', arrEmpty)

        var URL = state.urlWS + "action?insInc=" + idAus + "&iniDate=" + fIni + "&endDate=" + fFin;
        var URLTot = state.urlGateway + "?urlWs=" + URL + "&usu=" + state.user + "&pass=" + state.pass
        if (state.debug){ console.log(" > URLTot: " + URLTot); }

        if (state.debug) {
            return new Promise(function (resolve) {
                resolve();
            })
        } else {
            const instanceAxios = axios.create({
                httpsAgent: new https.Agent({
                    rejectUnauthorized: false
                })
            });
            const formData = new FormData();
            formData.append('urlWs', URL);
            formData.append('authCode', state.accessToken);

            return instanceAxios
                //.get(URLTot)
                .post(state.urlGateway, formData)
                .then(res2 => {
                    //Comprobamos que ha ido bien
                    if (res2.data.result == "OK") {
                        if (tipo == "D") {
                            var resJSON = res2.data.resultData;
                            if (typeof res2.data == "string") {
                                resJSON = JSON.parse(res2.data.replace(/\r?\n|\r/g, ' '));
                            }
                            commit('SET_DIARYVIEW', resJSON.diaryView)
                        }
                    } else {
                        throw res2.data.errorMsg;
                    }
                })
        }
    },
    async delRegistro({ commit, state }, { fecReg }) {

        if (state.debug){
            console.log("------------------------");
            console.log("actions > delRegistro");
            console.log(" #fecReg: " + fecReg);
            console.log("");

            console.log(" > Borramos el día y la semana!!")
        }
        let objEmpty = new Object();
        let arrEmpty = new Array();
        commit('SET_DIARYVIEW', objEmpty);
        commit('SET_WEEKVIEW', arrEmpty);

        var URL = state.urlWS + "action?delDay=" + fecReg;
        var URLTot = state.urlGateway + "?urlWs=" + URL + "&usu=" + state.user + "&pass=" + state.pass
        if (state.debug){
            console.log(" > URLTot: " + URLTot);
            console.log(" > state.debug: " + state.debug);
        }

        if (state.debug) {
            return new Promise(function (resolve) {
                resolve();
            })
        } else {
            const instanceAxios = axios.create({
                httpsAgent: new https.Agent({
                    rejectUnauthorized: false
                })
            });
            const formData = new FormData();
            formData.append('urlWs', URL);
            formData.append('authCode', state.accessToken);
            return instanceAxios
                //.get(URLTot)
                .post(state.urlGateway, formData)
                .then(); /*res2 => { 
                    //console.log(res2); 
                })*/
        }
    },
    async setReport({ state }, { fIni, fFin }) {

        var URL = state.urlWS + "action?setReport=&iniDate=" + fIni + "&endDate=" + fFin;
        if (state.debug){
            console.log(" > URL: " + URL);
            console.log(" > state.debug: " + state.debug);
        }
        if (state.debug) {
            return new Promise(function (resolve) {
                resolve();
            })
        } else {
            const instanceAxios = axios.create({
                httpsAgent: new https.Agent({
                    rejectUnauthorized: false
                })
            });
            const formData = new FormData();
            formData.append('urlWs', URL);
            formData.append('authCode', state.accessToken);

            return instanceAxios
                //.get(URLTot)
                .post(state.urlGateway, formData)
                .then(res2 => {
                    //console.log(res2);

                    //Comprobamos que ha ido bien
                    if (res2.data.result == "OK") {
                        console.log("El informe se ha mandado al mail correctamente")
                    } else {
                        throw res2.data.errorMsg;
                    }
                })
        }
    },
}