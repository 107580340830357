<template>
  <ion-grid style="height: calc(100% - 65px);">
    <ion-row justify-content-center align-items-center class="ionColCenter" style="height: 75%;">
      <div>
        <h1>Informe de horas</h1>
      </div>
      <div v-if="datosTabla==''">
        <ion-list>
          <ion-item-divider></ion-item-divider>
          <ion-item>
            <ion-label>Desde</ion-label>
            <ion-datetime
              cancel-text="Cancelar"
              done-text="Aceptar"
              display-format="MMM YYYY"
              picker-format="MMM YYYY"
              placeholder="Selecciona fecha"
              month-short-names="ene, feb, mar, abr, may, jun, jul, ago, sep, oct, nov, dic"
              :max="fecFinInforme"
              :value="fecIniInforme"
              @ionChange="fecIniInforme= $event.target.value"
            >></ion-datetime>
          </ion-item>
          <ion-item>
            <ion-label>Hasta</ion-label>
            <ion-datetime
              cancel-text="Cancelar"
              done-text="Aceptar"
              display-format="MMM YYYY"
              picker-format="MMM YYYY"
              placeholder="Selecciona fecha"
              :min="fecIniInforme"
              :max="this.dameHoy()"
              month-short-names="ene, feb, mar, abr, may, jun, jul, ago, sep, oct, nov, dic"
              :value="fecFinInforme"
              @ionChange="fecFinInforme= $event.target.value"
            ></ion-datetime>
          </ion-item>
        </ion-list>
        <ion-item>
          <div class="ion-padding"> 
            <ion-button
              shape="round"
              expand="block"
              type="submit"
              :disabled=" (fecIniInforme != '') && (fecFinInforme != '') ? false : true "
              @click="generarReport()"
            >Generar Informe</ion-button>
          </div>
        </ion-item>
      </div>
      <div v-else class="informeMeses">
        <div v-for="(itemMes, indexMes) in datosTabla" :key="indexMes" class="divInfContainer">
          <h3>{{itemMes.name}}</h3>
          <table class="tableCalInf">
            <tr class="titCalInf"><td class="diasBorde diasCabecera">L</td><td class="diasBorde diasCabecera">M</td><td class="diasBorde diasCabecera">X</td>
                <td class="diasBorde diasCabecera">J</td><td class="diasBorde diasCabecera">V</td><td class="diasBorde diasCabecera">S</td><td class="diasBorde diasCabecera">D</td></tr>
            <tr v-for="(itemSemana, indexSemana) in itemMes.datosMes" :key="indexSemana">
              <td
                v-for="(itemDia, indexDia) in itemSemana.datosSemana"
                :key="indexDia"
                class="diaNormal diasBorde"
                style="min-height: 55px;"
              >
                <div
                  v-if="$global.formatDate(itemDia.resultData.diaryView.fecRegistro,'DATE') >= itemMes.fIniMes && 
                        $global.formatDate(itemDia.resultData.diaryView.fecRegistro,'DATE') <= itemMes.fFinMes"
                  :class="((indexDia > 4)||(itemDia.resultData.diaryView.esFestivo == 'true')) ? 'findefestivo' : itemDia.resultData.diaryView.incEmpleado.length > 0 ? 'diaIncidencia' : itemDia.resultData.diaryView.secWorked != null ? itemDia.resultData.diaryView.HorSalidaReal != '' ? 'diaNormal' : 'diaIncompleto' : 'diaSinFich'"
                  style="height: 100%"
                >
                  <div style="color: gray;float:right;font-size: 12px;">
                    <span v-if="itemDia.resultData.diaryView.teletrabajo == 'S'">T </span>
                    <span>{{$global.formatDate(itemDia.resultData.diaryView.fecRegistro,'DAY')}}</span>
                  </div>
                  <br />
                  <div>
                    <center>
                      <span
                        style="font-size: 12px;"
                        v-if="(itemDia.resultData.diaryView.secWorked != null) && (itemDia.resultData.diaryView.HorSalidaReal != '') && (itemDia.resultData.diaryView.incEmpleado.length == 0)"
                      >{{$global.secondsToHms(itemDia.resultData.diaryView.secWorked).substring(0,5)}}</span>
                      <span
                        style="font-size: 12px;"
                        v-if="itemDia.resultData.diaryView.incEmpleado.length > 0"
                      >{{itemDia.resultData.diaryView.incEmpleado[0].name.substring(0,1)}}</span>
                    </center>
                  </div>
                </div>
              </td>
            </tr>
          </table>
        </div>
        <div class="ion-padding" style="clear:both;width:100%;padding: 5%;">
          <ion-button
            shape="round"
            expand="block"
            type="submit"
            @click="enviarReportMail()"
          >Enviar Correo</ion-button>
          <ion-button
            shape="round"
            expand="block"
            type="submit"
            @click="reiniciarReport()"
          >Seleccionar fechas</ion-button>
        </div>
      </div>
    </ion-row>
  </ion-grid>
</template>

<script>
export default {
  data: function() {
    return {
      fecIniInforme: this.dameHoy(), //"2020-06-08", //"",
      fecFinInforme: this.dameHoy(), //"2020-06-08", //"",
      datosTabla: ""
    };
  },
  created() {
    this.$store.commit("SET_LAYOUT", "principal-layout");
  },
  methods: {
    dameHoy: function () {
        var auxDate = new Date();
        var day = auxDate.getDate();
        var month = auxDate.getMonth() + 1;
        var year = auxDate.getFullYear();

        day = day < 10 ? "0" + day : day;
        month = month < 10 ? "0" + month : month;

        return year + "-" + month + "-" + day;
    },
    generarReport: function() {
      this.$global.ponerCarga();
      let fIni = this.fecIniInforme;
      let fFin = this.fecFinInforme;
      var that = this;

      this.$store
        .dispatch("wsReportInfo", { fIni: fIni, fFin: fFin })
        .then(res => {
          that.datosTabla = res;
          that.$global.quitarCarga();
        });
    },
    enviarReportMail: function() {
      this.$global.ponerCarga();
      let fIni = this.fecIniInforme;
      let fFin = this.fecFinInforme;
      var that = this;

      this.$store
        .dispatch("setReport", { fIni: fIni, fFin: fFin })
        .then(() => {
          that.$global.quitarCarga();
          that.$store.getters.alertIonic(
            "OK",
            "",
            "Envío realizado correctamente."
          );
        });
    },
    reiniciarReport: function() {
      this.datosTabla = "";
    }
  }
};
</script>