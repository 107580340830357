<template>
  <ion-menu side="start" content-id="menu-content" type="overlay">
    <ion-content id="menu-content">
      <ion-menu-toggle auto-hide="true">
        <router-link v-for="item in itemsFilter" :key="item.name" :to="{name: item.href}">
          <ion-button
            menu-toggle="left"
            expand="full"
            style="margin:0px;"
            :style="{ 'height': (100/itemsFilter.length) + '%', '--background': 'rgba('+item.colorIni+',1)','--background': '-moz-linear-gradient(left, rgba('+item.colorIni+',1) 0%, rgba('+item.colorFin+',1) 100%)','background': '-webkit-gradient(left top, right top, color-stop(0%, rgba('+item.colorIni+',1)), color-stop(100%, rgba('+item.colorFin+',1)))','background': '-webkit-linear-gradient(left, rgba('+item.colorIni+',1) 0%, rgba('+item.colorFin+',1) 100%)','background': '-o-linear-gradient(left, rgba('+item.colorIni+',1) 0%, rgba('+item.colorFin+',1) 100%)','background': '-ms-linear-gradient(left, rgba('+item.colorIni+',1) 0%, rgba('+item.colorFin+',1) 100%)','background': 'linear-gradient(to right, rgba('+item.colorIni+',1) 0%, rgba('+item.colorFin+',1) 100%)'}"
          >
            <div>
              <div style="width: 100%;padding: 5px;" class="imgBtnMnu">
                <!-- img :src="item.icon" style="width: 50px; position:absolute; top:25px; max-height: 60px;" / -->
                <img :src="item.icon" style="height: 50px;" />
              </div>
              <div style="width: 100%;padding: 5px;">
                <!-- span style="position:absolute;bottom: 20px;">{{ item.name }}</span -->
                <span>{{ item.name }}</span>
              </div>
            </div>
          </ion-button>
        </router-link>
      </ion-menu-toggle>
    </ion-content>
  </ion-menu>
</template>

<script>
export default {
  data() {
    return {
      angleOrientation: 0,
      items: [
        {
          name: "JORNADA ACTUAL",
          icon: "/assets/img/mnu/mnu1.png",
          href: "Principal",
          colorIni: "172,242,229",
          colorFin: "172,242,229"
        },
        {
          name: "RESUMEN SEMANAL",
          icon: "/assets/img/mnu/mnu1.png",
          href: "Semanal",
          colorIni: "126,203,200",
          colorFin: "126,203,200"
        },
        {
          name: "INFORME MENSUAL",
          icon: "/assets/img/mnu/mnu2.png",
          href: "Informe",
          colorIni: "154,208,236",
          colorFin: "99,172,208"
        },
        {
          name: "CONTACTA CON RRHH",
          icon: "/assets/img/mnu/mnu3.png",
          href: "Contacta",
          colorIni: "109,158,206",
          colorFin: "109,158,206"
        },
        /*{
          name: "AJUSTES",
          icon: "/assets/img/mnu/mnu4.png",
          href: "Ajustes",
          colorIni: "109,158,206",
          colorFin: "109,158,206"
        },*/
        {
          name: "CERRAR SESIÓN",
          icon: "/assets/img/mnu/mnu5.png",
          href: "Cerrar",
          colorIni: "226,163,119",
          colorFin: "217,115,215"
        }
      ]
    };
  },
  computed: {
    itemsFilter: function(){
      //item.href != this.$route.path.split('/')[1]
      //var auxRuta = (this.$route.path.split('/')[1]);
      /*
      var arrItemsFilter = window.$.grep(this.items, function(n){
        return n.href.toLowerCase() != auxRuta.toLowerCase();
      });
      */
      return this.items;
    },
    hScreen: function(){ 
      return screen.height
    }
  },
  mounted() {
    var that = this;
    window.addEventListener("orientationchange", function() {
      console.log("Orientation of the device is " + screen.orientation.angle);
      that.angleOrientation = screen.orientation.angle;
    });
  }
};

</script>

<style scoped>
ion-list {
  padding: 0;
}

ion-item {
  --ion-background-color: transparent;
}
</style>


