import Vue from 'vue'
import App from './App'
import router from './router'
import VueRouter from 'vue-router'
import {store} from './store' // importamos el modulo de store
import BootstrapVue from 'bootstrap-vue'
import Ionic from '@ionic/vue';
import global from '@/global.js';
import Datetime from '@/components/vue-datetime'


import '@ionic/core/css/ionic.bundle.css';
//import 'vue-datetime/dist/vue-datetime.css'

window.$ = window.jQuery = require('jquery')

Vue.use(BootstrapVue)
Vue.use(VueRouter);
Vue.use(Ionic);
Vue.use(Datetime);
Vue.config.productionTip = false 

Object.defineProperty(Vue.prototype, '$global', { value: global });

new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
