'use strict'

import Vue from 'vue'
import Vuex from 'vuex'
import Mutations from '@/store/mutations.js'
import Actions from '@/store/actions.js'
import Getters from '@/store/getters.js'
import createPersistedState from 'vuex-persistedstate'
//import * as Cookies from 'js-cookie'

Vue.use(Vuex)

export const store = new Vuex.Store({
  state: {
    layout: 'login-layout',
    credential: null,
    userName: '',
    diaryView: new Object(),
    weekView: new Array(),
    jsonConfig: new Object(),
    urlWS: (window.location.host.includes("staging-iis")) ? "https://gsktest.pn.cegid.cloud/REST/" : "https://gsk.pn.cegid.cloud/REST/",
    //urlWS: "http://uk1sawn01831.wmservice.corpnet1.com:8080/REST/",   //DESA
    //urlWS: "https://uk1sawn01869.wmservice.corpnet1.com/REST/",       //PROD 
    //urlWS: "http://uk1sawn01869.wmservice.corpnet1.com:8080/REST/",   //PROD NO SEC
    entorno: (window.location.host.includes("staging-iis")) ? "DESA" : "PROD",
    urlGateway: "/gateway.aspx",
    rejectUnauthorized: true,
    user: null,
    pass: null,
    debug: (window.location.host == "localhost:8080") ? true : false,
    lastgetSecWorkedStored: null,
    //Variables del pingID
    authURL: (window.location.host.includes("staging-iis")) ? 'https://federation-qa.gsk.com/as/authorization.oauth2' : 'https://federation.gsk.com/as/authorization.oauth2',
    introspectionRUL: (window.location.host.includes("staging-iis")) ? 'https://federation-qa.gsk.com/as/introspect.oauth2' : '',
    tokenURL: "/getAuthToken.aspx",
    redirectURL: (window.location.host.includes("staging-iis")) ? 'https://optime-gsk-es.staging-iis.gskinternet.com/'  : 'https://optime.gsk.es/',
    clientId: 'Optime',
    clientSecret: (window.location.host.includes("staging-iis")) ? 'BuPjMgpL15LEcZ25OZg0dVdccllPHbmPaXgdZMPASynxnscEYITlElSKKfdLYjArh6Tu' : 'tn4mESFkQcfg4U2gQmMpLD4FsQYJ94kUHAfOlACopgFAfniArQe4ExqqUwqHK39rYzJj',
    intermCode: '',
    accessToken: ''
  },
  mutations: Mutations,
  actions: Actions,
  getters: Getters,
  plugins: [
    createPersistedState({
      storage: window.sessionStorage
    })
  ]
})
