<template>
  <div class="sliderOptionsMain">
    <button
      type="button"
      id="btnEntrad"
      :class="btnClass"
      :style="btnStyle"
      @click="open"
    >{{titleBtn}}</button>
    <transition-group name="vdatetime-fade" tag="div">
      <div key="overlay" v-if="isOpen" class="sliderOptions-overlay" @click.self="cancel"></div>
      <div key="popup" v-if="isOpen && activeSlider" class="sliderOptions-popup">
        <div class="sliderOptions-popup__header">{{titlePopup}}</div>
        <div class="sliderOptions-popup__body">
          <div class="sliderOptions__list" ref="optionList">
            <div
              class="sliderOptions__item"
              v-for="(option, indexOption) in arrOptions"
              :key="indexOption"
              @click="selectOp(indexOption)"
            >{{ option.id }} - {{ option.name }}</div>
          </div>
        </div>
        <div class="sliderOptions-popup__actions">
          <div
            class="sliderOptions__actions__button sliderOptions__actions__button--cancel"
            @click="cancel"
          >{{ phrases.cancel }}</div>
          <div
            class="sliderOptions__actions__button sliderOptions__actions__button--confirm"
            @click="confirmSlider"
            v-if="Object.keys(optionSelected).length"
          >{{ phrases.ok }}</div>
        </div>
      </div>
      <datetime
        key="datetime"
        :input-id="inputId"
        format="yyyy-MM-dd"
        v-model="objFecSlider"
        class="theme-orange"
        input-style="display:none"
        :type="type"
        @confirm="confirmDatetime"
      ></datetime>
    </transition-group>
  </div>
</template>

<script>
export default {
  name: "sliderOptions",
  data() {
    return {
      isOpen: false,
      optionSelected: (this.arrOptions.length == 1) ? this.arrOptions[0] : new Object(), 
      objFecSlider: {
        fecIni: "",
        fecFin: ""
      },
    };
  },
  computed:{
    activeSlider() {
      return this.arrOptions.length > 1 ? true : false
    }
  },
  props: {
    arrOptions: {
      type: Array,
      default: null
    },
    phrases: {
      type: Object,
      default() {
        return {
          cancel: "Cancel",
          ok: "Ok"
        };
      }
    },
    titlePopup: {
      type: String,
      default: null
    },
    titleBtn: {
      type: String,
      default: null
    },
    btnClass: {
      type: [Object, Array, String],
      default: ""
    },
    btnStyle: {
      type: [Object, Array, String],
      default: ""
    },
    type: {
      type: String,
      default: ""
    },
    inputId: {
      type: String,
      default: ""
    }
  },
  methods: {
    open(){
      if(this.activeSlider){
        this.isOpen = true;
      }else{
        window.$("#" + this.inputId).click();
      }
    },
    confirmSlider() {
      if(this.type !== ""){
        window.$("#" + this.inputId).click();
      }else{
        this.$emit("confirm", {'option':this.optionSelected,'fecSel':this.objFecSlider});
        this.close();
      }
    },
    confirmDatetime(){
      this.$emit("confirm", {'option':this.optionSelected,'fecSel':this.objFecSlider});
      this.close();
    },
    cancel() {
      this.close();
    },
    close() {
      this.isOpen = false;
      this.$emit("close");
    },
    selectOp(i){
      this.optionSelected = this.arrOptions[i];
    }
  }
};
</script>

<style scoped>
.sliderOptionsMain {
  width: 50%;
  padding: 0px;
  margin: 5px;
}

.sliderOptionsMain > button {
  width: 100%;
  margin: 0px;
}

.sliderOptions-overlay {
  z-index: 999;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  transition: opacity 0.5s;
}

.sliderOptions-popup {
  box-sizing: border-box;
  z-index: 1000;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 340px;
  max-width: calc(100% - 30px);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3);
  color: #444;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  line-height: 1.18;
  background: #fff;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  & * {
    box-sizing: border-box;
  }
}

.sliderOptions-popup__header {
  padding: 18px 30px;
  background: #3f51b5;
  color: #fff;
  font-size: 32px;
}

.sliderOptions-popup__actions {
  padding: 0 20px 10px 30px;
  text-align: right;
}

.sliderOptions__list {
  float: left;
  width: 100%;
  height: 305px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-track {
    background: #efefef;
  }

  &::-webkit-scrollbar-thumb {
    background: #ccc;
  }
}

.sliderOptions__item {
  padding: 10px 0;
  font-size: 20px;
  text-align: center;
  cursor: pointer;
  transition: font-size 0.3s;
}

.sliderOptions__item:hover {
  font-size: 32px;
}

.sliderOptions__actions__button {
  display: inline-block;
  border: none;
  padding: 10px 20px;
  background: transparent;
  font-size: 16px;
  color: #3f51b5;
  cursor: pointer;
  transition: color 0.3s;

  &:hover {
    color: #444;
  }
}
</style>