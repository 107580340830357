<template>
  <ion-grid style="height: 100%">
    <ion-row justify-content-center align-items-center class="ionRowCenter">
      <h2 class="fontGill">Si tienes alguna duda</h2>
      <h2 class="fontGill">contacta con</h2><br/>

      <div class="iconGSK">
        <ion-icon name="mail"></ion-icon>
        <a class="fontGill infoContacto" 
           :href="'mailto:'+this.config.ctc_email+'?subject='+this.config.ctc_emailAsunto">
            {{this.config.ctc_email}}
        </a>
      </div>

      <div class="iconGSK">
        <ion-icon name="phone-portrait"></ion-icon>
        <h2 class="fontGill infoContacto">{{this.config.ctc_telefono}}</h2>
      </div>

    </ion-row>
  </ion-grid>
</template>


<script>
export default {
  data: function() {
    return {
      config: new Object()
    };
  },
  created() {
    this.$store.commit("SET_LAYOUT", "principal-layout");
    this.config = this.$store.getters.getJsonConfig;
  }
};
</script>