<template>
  <keep-alive>
    <component :is="layout"></component>
  </keep-alive>
</template>
<script>
  import LoginLayout from './components/layouts/Login'
  import PrincipalLayout from './components/layouts/Principal'
  import {mapState} from 'vuex'
  import 'bootstrap/dist/css/bootstrap.css'
  import 'bootstrap-vue/dist/bootstrap-vue.css'


  export default {
    components: {LoginLayout, PrincipalLayout},
    computed: mapState(['layout'])
  }
</script>