<template>
  <ion-content padding id="loginContent">
    <ion-row justify-content-center align-items-center class="ionRowCenter" id="imgCarga">
      <div><img src="/assets/img/optimeLogo.png" style="width: 250px;" /></div>
      <div class="cssload-container"> 
        <div class="cssload-bouncywrap"> 
          <div class="cssload-cssload-dotcon cssload-dc1"> <div class="cssload-dot"></div> </div> <div class="cssload-cssload-dotcon dc2"> <div class="cssload-dot"></div> </div> <div class="cssload-cssload-dotcon dc3"> <div class="cssload-dot"></div> </div> 
        </div> 
      </div>
    </ion-row>
    
    <ion-alert-controller></ion-alert-controller>
  </ion-content>
</template>


<script>

export default {
  beforeCreate(){    
    sessionStorage.clear();
  },
  async created() {    
    await this.$store.commit("SET_LAYOUT", "login-layout");
    if(this.$store.getters.getDebug){
      this.$store.dispatch('wsLogin').then(() => {
        //Si el logado ha sido correcto, solicitamos la información diaria
        this.$store.dispatch('wsDiaryView',{'fecdia':null,'act':true}).then(() => {
          //Si todo OK y los datos están cargados... pasamos a la pantalla principal
          this.$router.push("/principal");
        })
      })
    }else{
      var authCode = await this.$store.dispatch('getUrlParameter', {'sParam':'code'})
      if(typeof authCode !== "undefined") {
        //this.$store.dispatch('swapAuthCodeForAccessToken',{'authCode':authCode});
        var that = this;
        this.$store.dispatch('swapAuthCodeForAccessToken',{'authCode':authCode}).then(() => {
          //Si el logado ha sido correcto, solicitamos la información diaria
          this.$store.dispatch('wsDiaryView',{'fecdia':null,'act':true}).then(() => {
            //Si todo OK y los datos están cargados... pasamos a la pantalla principal
            this.$router.push("/principal");
          })
        }).catch(function() {
          that.$store.getters.alertIonic("OK", "Error", "Error al obtener la sesión. Por favor consulta con el administrador");
        });
      }else{
        console.log(this.$store);
        let clientID = this.$store.getters.getClientId;
        let authURL = this.$store.getters.getAuthURL;
        let redirectURL = this.$store.getters.getRedirectURL;
        
        const URL = authURL + "?client_id=" + clientID + "&response_type=code&scope=openid&redirect_uri="+encodeURIComponent(redirectURL);
        window.location.replace(URL);
      }
    }
    
  }
};
</script>

<style scoped>
@import "/assets/css/global.css";
@import "/assets/css/login.css";
</style>