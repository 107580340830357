<template>
    <ion-grid style="height: calc(100% - 65px);">
      <ion-row justify-content-center align-items-center class="ionRowCenter">
        <h2 style="text-align: center;">TE HAS DESCONECTADO DE OPTIME</h2><br/>
        <img src="/assets/img/logoPeque.png" style="width:90px;" />
      </ion-row>
    </ion-grid>
</template>

<script>
export default {
  created() {
    this.$store.commit("SET_CREDENTIAL", null);
    sessionStorage.clear();
    this.$store.getters.alertIonic(
      "session",
      "Desconexión",
      "Su sesión ha sido cerrada"
    )
    //this.$store.commit("SET_LAYOUT", "login-layout");
  }
};
</script>